<template>
    <div>
        <v-overlay v-if="isLoaderDisplayed" :value="true" :absolue="true">
            <v-progress-circular
                size="100"
                width="15"
                indeterminate
                color="primary"
                class="loader"
            />
        </v-overlay>

        <time-report-dashboard
            v-else
            :isDataLoading="isDataLoaderDisplayed"
            :userTimeReportInfoArray="userTimeReportInfoArray"
            :periodDateArray="periodDateArray"
            :detailYearMonth="detailYearMonth"
            :detailGid="detailGid"
            :pageSpecificDetails="pageSpecificDetails"
            :total-page="totalPage"
            @period-change="onPeriodChange"
            @page-size-change="onPageSizeChange"
            @page-change="onPageChange"
            @filter-change="onFilterChange"
        />
    </div>
</template>

<script>
import moment from 'moment';
import colors from '@/styles/colors.scss';

import messagesConstants from '@/constants/messages.constants';
import dateFormatConstants from '@/constants/date-format.constants';
import { USER_ROLES } from '@/constants/utils.constants';

import workedDaysService from '@/services/worked-days.service';
import periodReportSummaryService from '@/services/period-report-summary.service';
import periodReportSummaryApiService from '@/services/period-report-summary-api.service';

import TimeReportDashboard from '@/components/time-report-dashboard';

export default {
    name: 'hr-page',

    components: {
        TimeReportDashboard,
    },

    data() {
        return {
            userTimeReportInfoArray: [],
            periodDateArray: [],

            detailYearMonth: undefined,
            detailGid: undefined,
            pageSpecificDetails: {
                backgroundColor: {
                    default: 'accentHr',
                    validated: 'primaryHr',
                },
                role: USER_ROLES.HR,
            },

            isLoaderDisplayed: false,
            isDataLoaderDisplayed: false,

            periodStartDate: undefined,
            periodEndDate: undefined,

            nameFilter: '',

            pageSizeItems: [20, 50, 100],
            totalPage: 1,
        };
    },

    async created() {
        this.loadHrTheme();

        if (this.$route.query && this.$route.query['year-month'] && this.$route.query.coworkerGid) {
            this.detailYearMonth = this.$route.query['year-month'];
            this.detailGid = this.$route.query.coworkerGid;
            await this.$router.replace({ query: null });
        } else {
            await this.onPeriodChange(moment().format(dateFormatConstants.YEAR_MONTH_FORMAT));
        }
    },

    methods: {
        async onPeriodChange(yearMonth) {
            this.displayLoader();
            this.periodDateArray = [];
            this.detailYearMonth = undefined;
            this.detailGid = undefined;

            const periodRange = workedDaysService.getRangePeriod(yearMonth);
            this.periodStartDate = periodRange.startDate;
            this.periodEndDate = periodRange.endDate;
            this.periodDateArray = workedDaysService.getDatesBetweenTwoDates(
                this.periodStartDate,
                this.periodEndDate,
                'months',
                dateFormatConstants.YEAR_MONTH_FORMAT
            );
            this.hideLoader();

            await this.loadPeriodReportSummaryAndPageParam(
                this.$store.state.userGid,
                this.periodStartDate,
                this.periodEndDate,
                this.nameFilter,
                this.pageSizeItems[0],
                1
            );
        },
        async onPageSizeChange(pageSize) {
            await this.loadPeriodReportSummaryAndPageParam(
                this.$store.state.userGid,
                this.periodStartDate,
                this.periodEndDate,
                this.nameFilter,
                pageSize,
                1
            );
        },
        async onPageChange(pageSize, page) {
            await this.loadPeriodReportSummaryAndPageParam(
                this.$store.state.userGid,
                this.periodStartDate,
                this.periodEndDate,
                this.nameFilter,
                pageSize,
                page
            );
        },
        async onFilterChange(filter, pageSize, page) {
            this.nameFilter = filter;

            await this.loadPeriodReportSummaryAndPageParam(
                this.$store.state.userGid,
                this.periodStartDate,
                this.periodEndDate,
                this.nameFilter,
                pageSize,
                page
            );
        },

        async loadPeriodReportSummaryAndPageParam(
            userGid,
            periodStartDate,
            periodEndDate,
            nameFilter,
            pageSize,
            pageNumber = 1
        ) {
            this.displayDataLoader();
            this.userTimeReportInfoArray = undefined;

            const refereesPeriodReportSummaryArrayPage =
                await this.getRefereesPeriodReportSummaryArrayPage(
                    userGid,
                    periodStartDate,
                    periodEndDate,
                    nameFilter,
                    pageSize,
                    pageNumber
                );

            this.userTimeReportInfoArray = await this.getUserTimeReportInfoArray(
                refereesPeriodReportSummaryArrayPage.userPeriodReportSummaryArray,
                periodStartDate,
                periodEndDate
            );

            this.totalPage = refereesPeriodReportSummaryArrayPage.totalPage;

            this.hideDataLoader();
        },
        async getUserTimeReportInfoArray(
            refereesPeriodReportSummaryArray,
            periodStartDate,
            periodEndDate
        ) {
            try {
                return await periodReportSummaryService.buildUserTimeReportInfoArray(
                    refereesPeriodReportSummaryArray,
                    periodStartDate,
                    periodEndDate
                );
            } catch (e) {
                this.hideDataLoader();
                this.$header.addToast(
                    'error',
                    messagesConstants.GET_PUBLIC_HOLIDAYS_FAILED.TITLE,
                    messagesConstants.GET_PUBLIC_HOLIDAYS_FAILED.MESSAGE
                );
                throw e;
            }
        },
        async getRefereesPeriodReportSummaryArrayPage(
            hrGid,
            periodStartDate,
            periodEndDate,
            nameFilter,
            page,
            pageSize
        ) {
            try {
                return await periodReportSummaryApiService.getHumanResourcesPeriodReportSummaries(
                    hrGid,
                    periodStartDate,
                    periodEndDate,
                    nameFilter,
                    page,
                    pageSize
                );
            } catch (e) {
                this.hideDataLoader();
                this.$header.addToast(
                    colors.error,
                    messagesConstants.GET_HR_REFEREES_FAILED.TITLE,
                    messagesConstants.GET_HR_REFEREES_FAILED.toParamString(hrGid)
                );
                throw e;
            }
        },

        displayLoader() {
            this.isLoaderDisplayed = true;
        },
        hideLoader() {
            this.isLoaderDisplayed = false;
        },
        displayDataLoader() {
            this.isDataLoaderDisplayed = true;
        },
        hideDataLoader() {
            this.isDataLoaderDisplayed = false;
        },
        loadHrTheme() {
            const theme = {
                primary: colors.primaryHr,
                secondary: colors.secondaryHr,
                accent: colors.accentHr,
            };
            this.$emit('change-theme', theme);
        },
    },
};
</script>
